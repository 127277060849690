import request from './request';

const CountryService = {
  get: (params) => request.get('dashboard/admin/findex', { params }),
  getById: (id, params) =>
    request.get(`dashboard/admin/findex/${id}`, { params }),
  create: (params) =>
    request.post('dashboard/admin/findex', {}, { params }),
  update: (id, data) => request.put(`dashboard/admin/findex/${id}`, data),
  delete: (id, params) => request.delete(`dashboard/admin/findex/${id}`),
};

export default CountryService;
