import React, { useState, useEffect } from 'react';
import { Form, Input, Card, Button, Row, Col, InputNumber } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import CountryService from '../../services/delivery-of-country';
import { fetchDeliveriesCountry } from '../../redux/slices/country';
import { removeFromMenu } from '../../redux/slices/menu';
import { toast } from 'react-toastify';

const DeliveryOfCountryAdd = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { activeMenu } = useSelector((state) => state.menu);
 

  const onFinish = (values) => {
    const params = {
      ...values,
      price: Number(values.price),
    };
    const nextUrl = 'delivery-of-country/list';
    if (!id) {
      CountryService.create(params)
        .then(() => {
          toast.success(t('successfully.created'));
          dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
          dispatch(fetchDeliveriesCountry());
          navigate(`/${nextUrl}`);
        })
        .finally();
    } else {
      CountryService.update(id, params)
        .then(() => {
          toast.success(t('successfully.updated'));
          dispatch(removeFromMenu({ ...activeMenu, nextUrl }));
          dispatch(fetchDeliveriesCountry());
          navigate(`/${nextUrl}`);
        })
        .finally();
    }
  };

  useEffect(() => {
    if (id) {
     
      CountryService.getById(id)
        .then((res) => {
          form.setFieldsValue(res.data);
        })
        .finally();
    }
  }, [id]);

  return (
    <Card
      title={id ? t('edit.delivery.of.country') : t('add.delivery.of.country')}
    >
      <Form
        form={form}
        name='basic'
        layout='vertical'
        onFinish={onFinish}
      >
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              label={t('id')}
              name='id'
              rules={[{ required: true, message: t('required') }]}
            >
              <Input disabled={id !== undefined} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('country')}
              name='country'
              rules={[{ required: true, message: t('required') }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('price')}
              name='price'
              rules={[{ required: true, message: t('required') }]}
            >
              <InputNumber min={0} className='w-100' />
            </Form.Item>
          </Col>
        </Row>
        <Button type='primary' htmlType='submit'>
          {t('save')}
        </Button>
      </Form>
    </Card>
  );
};

export default DeliveryOfCountryAdd;
