import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import countryService from '../../services/delivery-of-country';
import sellerCountryService from '../../services/seller/country';

const initialState = {
  loading: false,
  countrydeliveries: [],
  error: '',
  params: {
    page: 1,
    perPage: 10,
  },
  meta: {},
};

export const fetchDeliveriesCountry = createAsyncThunk(
  'country/fetchDeliveries',
  (params = {}) => {
    return countryService
      .get({ ...initialState.params, ...params })
      .then((res) => res);
  }
);
export const fetchSellerCountryDeliveries = createAsyncThunk(
  'country/fetchSellerCountryDeliveries',
  (params = {}) => {
    return sellerCountryService
      .get({ ...initialState.params, ...params })
      .then((res) => res);
  }
);

const countrySlice = createSlice({
  name: 'country',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchDeliveriesCountry.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeliveriesCountry.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.countrydeliveries = payload.data;
    });
    builder.addCase(fetchDeliveriesCountry.rejected, (state, action) => {
      state.loading = false;
      state.countrydeliveries = [];
      state.error = action.error.message;
    });

    builder.addCase(fetchSellerCountryDeliveries.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSellerCountryDeliveries.fulfilled, (state, action) => {
      const { payload } = action;
      state.loading = false;
      state.countrydeliveries = payload.data;
    });
    builder.addCase(fetchSellerCountryDeliveries.rejected, (state, action) => {
      state.loading = false;
      state.countrydeliveries = [];
      state.error = action.error.message;
    });
  },
});

export default countrySlice.reducer;
