export const PROJECT_NAME = 'ShopCi';
export const BASE_URL = 'https://bledardgroup.com';
export const WEBSITE_URL = 'https://shops-ci.com';
export const api_url = BASE_URL + '/api/v1/';
export const api_url_admin = BASE_URL + '/api/v1/dashboard/admin/';
export const api_url_admin_dashboard = BASE_URL + '/api/v1/dashboard/';
export const IMG_URL = '';
export const MAP_API_KEY = 'AIzaSyCd-S1Hqf_CbgmNlPmhkllkD2--GwvuU2U';
export const export_url = BASE_URL + '/storage/';
export const VAPID_KEY =
  'BELRtJFV58xUGHWgiIzXtgbZjZAj8v8C6vvgzKOjB1JscIX9HL5u8ejr2UKpgVfYsJqWejBNvRntrfGMZuH7OMA';

export const RECAPTCHASITEKEY = '6LeHiT4oAAAAAFYWzgCkv0dg5fIwFQFdfeC6w3Wx';
export const API_KEY = 'AIzaSyD8RdJZZO4_h7hUC5kC8whmEo8pdUWOxSg';
export const AUTH_DOMAIN = 'ornella-5b24b.firebaseapp.com';
export const PROJECT_ID = 'ornella-5b24b';
export const STORAGE_BUCKET = 'ornella-5b24b.appspot.com';
export const MESSAGING_SENDER_ID = '282329538863';
export const APP_ID = '1:282329538863:web:7561aaf80fae0b1786c1da';
export const MEASUREMENT_ID = 'G-3TL1RJ3676';
export const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/svg',
];

export const DEMO_SELLER = 210; // Seller_id
export const DEMO_SHOP = 599; // seller_id
export const DEMO_DELIVERYMAN = 375; // deliveryman_id
export const DEMO_MANEGER = 114; // deliveryman_id
export const DEMO_MODERATOR = 297; // deliveryman_id
export const DEMO_ADMIN = 107; // deliveryman_id



