import React from 'react';
import { Button, Card, Result } from 'antd';
import { useNavigate } from 'react-router-dom';

export default function Welcome() {
  const navigate = useNavigate();

  return (
    <div className='global-settings'>
      <Card className='h-100 d-flex justify-content-center align-items-center'>
        <Result
          status='success'
          title='Merci d"avoir choisit shops-ci!'
          subTitle='Allez aux étapes suivantes du projet pour dévenir propriétaire.'
          extra={[
            <Button
              type='primary'
              key='installation'
              onClick={() => navigate('/installation')}
            >
              Allez à l"installation
            </Button>,
          ]}
        />
      </Card>
    </div>
  );
}
